.header {
    width: 100%;
    padding-bottom: 10px;
    margin-bottom: 30px;

    &__content {
        display: block;
        color: #000;
        line-height: 1;
        font-size: 40px;
        font-weight: 600;

        span {
            font-weight: 200;
        }
    }

    &__image {
        display: block;
        text-align: right;
        margin-bottom: 40px;

        img {
            width: 149px;
        }
    }
}

@media (min-width: 768px) {
    .header {
        &__content {
            font-size: 52px;
        }

        &__image {

            img {
                width: 198px;
            }
        }
    }
}
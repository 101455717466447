.intro {
    margin-bottom: 60px;
    display: inline-block;
    line-height: 1.1;
    font-size: 26px;
    font-weight: 200;

    a {
        color: $swiss-color-red;
    }
}

@media (min-width: 768px) {
    .intro {
        font-size: 28px;
    }
}

.content {
    display: grid;
    grid-template-columns: 1.1fr 0.9fr;
    gap: 75px;
    
    @media screen and (max-width: 890px) {
       gap: 0;
       grid-template-columns: 1fr;
    }

    .motif-row {    
        margin-left: 74px;
        display: inline-block;
        width: calc(100% - 74px);
        margin-right: 10px;
    
        &__image {
            width: calc(33% - 5px);
            height: 100%;
            display: inline-block;
            cursor: pointer;
            padding: 5px;
            margin-bottom: 10px;
            margin-right: 3px;
    
            &.active,
            &:hover {
                background: $swiss-color-grey-m4;
            }
    
            img {
                width: 100%;
            }
        }
    
        span {
            font-size: 15px;
            color: #000;
        }
    }

    .form {
        &__left {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__right {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            padding-bottom: 30px;

            > * {
                margin-left: 20px;
            }

            @media (min-width: 768px) {
                flex-direction: row;
            }
        }

        &__row {
            // display: block;
            // height: auto;
            position: relative;
            display: flex;

            select[name="motive"] {
                display: none;
            }

            &[data-active="1"] {
                .form__shadow {
                    display: none;
                }
            }

            &[data-final-step] {
                a {
                    cursor: pointer;
                }
                .form__step {
                    border-color: $swiss-color-grey-m3;
                    

                    span {
                        top: calc(50% + 2px);
                    }

                    svg {
                        width: 25px;
                        height: 25px;

                        * {
                            fill: #000;
                        }
                    }
                }

                &[data-active="1"] {
                    .form__step {
                        svg {
                            * {
                                fill: $swiss-color-red;
                            }
                        }
                    }
                    .form__label {
                        padding: 20px 46px 20px 44px;
                        background: $swiss-color-red;
                    }
                }
                .form__label {
                    padding: 20px 46px 20px 44px;
                    background: $swiss-color-grey-m3;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 600;
                    margin-left: 16px;
                    border-radius: 2px;
                    flex-grow: 0;
                    text-decoration: none;
                }
            }
        }

        &__shadow {
            display: block;
            background: rgba(255, 255, 255, 0.7);
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 99;
        }

        &__step {
            display: inline-block;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            position: relative;
            background: #fff;
            border: 2px solid $swiss-color-grey-m5;
            z-index: 1;
            cursor: pointer;

            span {
                font-weight: 600;
                font-size: 20px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate3d(-50%, -50%, 0);
            }
        }

        &__row[data-step="1"] .form__step {
            background: $swiss-color-grey-m5;
        }

        &__label {
            display: inline-block;
            text-align: left;
            vertical-align: 18px;
            padding-top: 14px;

            span {
                // position: absolute;
                // bottom: 4px;
                // left: 76px;
                display: block;
                color: $swiss-color-red;
                font-size: 11px;
            }
        }

        &__input {
            display: inline-block;
            text-align: right;
            vertical-align: middle;
            text-align: right;
            float: right;
            margin-top: 12px;

            select,
            input {
                width: 100%;
                font-weight: normal;
            }

            input {
                outline: none;
                padding: 5px 10px 5px 0;
                border: 0;
                border-bottom: 1px solid #000;
            }

            input + input {
                margin-top: 8px;
            }

            textarea {
                outline: none;
                padding: 5px 0;
                border: 0;
                border-bottom: 1px solid #000;
                height: 32px;
                width: 100%;
            }
        }

        &__divider {
            flex-grow: 1;
            width: 1px;
            background-color: $swiss-color-grey-m5;
        }

        &__row:not([data-step="1"]) .form__divider {
            display: none;
        }

        @media (min-width: 768px) {
            &__label {
                flex-grow: 1;
            }

            &__input {
                width: 200px;
            }
        }
    }

    .preview {
        position: relative;
        margin-top: 10px;

        &__container {
            display: grid;
            align-items: center;
            justify-content: center;
            position: relative;
            border: 1px solid $swiss-color-grey-m3;

            &::before {
                grid-row-start: 1;
                grid-column-start: 1;
                content: '';
                width: 100%;
                padding-top: 100%;
            }
        }

        &__file  {
            grid-row-start: 1;
            grid-column-start: 1;
            display: none;
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;

            img {
                max-width: 100%;
                width: 100%;
            }
        }

        &__loading {
            position: absolute;
            height: 100%;
            width: 100%;
            background: rgba(0, 0, 0, 0.4);
            z-index: 999;
            display: none;    
            left: 0;

            &.load {
                display: block;
            }
        }

        &__no-preview {
            grid-row-start: 1;
            grid-column-start: 1;
            line-height: 1.3;
            font-size: 14px;
            color: $swiss-color-grey-m3;
            text-align: center;
            padding: 70px;
        }
    }
}


@media (min-width: 768px) {
    .content {
        .form {
            &__label {
                padding-top: 16px;
            }

        }
    }
}
body {
    * {
        box-sizing: border-box;
    }

    .content-wrapper {
        display: block;
        margin: 0 auto;
        max-width: 1024px;
        padding: 20px;

        .content-container {
            margin: 0 auto;
        }
    }
}